<template>
    <recess-modal :show-modal="isModalVisible" @close="$emit('close')">
        <template slot="modal-title">
            <h3 class="qa-warning-kvk-popup-title"> {{SHARED.LegalCompany.Popup.Warning.Title}} </h3>
        </template>
        <template slot="modal-body"> 
            <p class="qa-warning-kvk-popup-description">
                {{SHARED.LegalCompany.Popup.Warning.Text}}
                <br/>
                <slot/>
                <br/>
                {{SHARED.LegalCompany.Popup.Warning.ConfirmationQuestion}}
            </p> 
        </template>
        <template slot="modal-footer">
            <div class="d-flex justify-content-end mt-5">
                <recess-button
                    variant="secondary"
                    :title="BUTTON_TEXT.save"
                    class="mr-2 qa-warning-kvk-popup-creation" 
                    @click.prevent.native="$emit('confirmTriggered')"
                />
            </div>
        </template>
    </recess-modal>
</template>

<script>
import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { SHARED } from "../../constants/EdumsSharedConstants.json"

export default {
    name: 'WarningKvkPopup',
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            SHARED,
            BUTTON_TEXT
        }
    }
}
</script>